<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template v-slot:centered>
      <v-form>
        <v-card class="my-card rounded-lg" elevation="0">
          <v-card-title>QuizCraft </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="topic"
              class="mt-4 ml-2"
              label="Topic/Subject"
              outlined
              
            />
          
            <v-textarea
      v-model="performanceIndicator"
      class="ml-2"
      label="Performance indicator / Specific learning objectives"
      hint="Optional"
      outlined
    >
      <template v-slot:append-outer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="standardsDialog=true" v-bind="attrs" v-on="on">mdi-format-list-bulleted</v-icon>
          </template>
          <span>Choose from list</span>
        </v-tooltip>
      </template>
            </v-textarea>
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="grade"
              :items="['K','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12']"
              label="Suitable for Grade level"
              outlined
              clearable
            />

            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="quizType"
              :items="['Open ended', 'Multiple Choice', 'Short answer']"
              label="Type"
              outlined
              clearable
              hint="Optional"
            />

            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="quizItemCount"
              :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
              label="Numer of Questions"
              outlined
              clearable
            />

            <v-textarea
              v-model="modelItem"
              label="Give me a sample item if you want me to create similar items. "
              outlined
              height="160"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>

            <v-btn :disabled="(!topic)&&(!performanceIndicator)" color="#212121" @click="sendRequest()">
              <h3 v-if="(!topic)&&(!performanceIndicator)">Generate please</h3>
              <h3 v-else style="color: white">Generate please</h3>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
    <v-dialog v-model="standardsDialog" max-width="400">
<v-card outlined elevation="10">
  <v-card-title> Choose learning standard</v-card-title>
 
  <v-card-text>
     <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="standardState"
              :items="['NextGen NewYork', 'coming soon']"
              label="Choose the Standards"
              outlined
              clearable
            />
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="standardcourse"
              :items="['ELA', 'Math']"
              label="Choose the Standards"
              outlined
              clearable
            />
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="standardGrade"
              :items="['K','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12']"
              label="Grade level"
              outlined
              clearable
            />
            <v-btn @click="searchStandards()"> Search Standards</v-btn>
            <v-divider></v-divider>
              <v-select
              v-if="viewstandarselection"
              class="ml-2"
              append-icon="mdi-filter"
              v-model="selectedLearningStandard"
              :items="learnignStandards"
              :item-text="item => `${item.Standard} ${item.Description}`"
              label="Select the Standard"
              outlined
              clearable
            />
            
  </v-card-text>
  <v-card-actions>
    <v-spacer />
    <v-btn @click="standardsDialog=false"> Cancel</v-btn>
    <v-btn @click="standardsDialog=false, performanceIndicator=selectedLearningStandard"> OK </v-btn>
  </v-card-actions>
</v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
  data() {
    return {
      what: "Quiz",
      topic: null,
      grade: null,
      performanceIndicator: null,
      modelItem: null,
      isLoading: false,

      quizType: null,
      quizItemCount: null,

      standardsDialog:false,
      standardGrade:null,
      standardState:null,
      standardcourse:null,
      selectedLearningStandard:null,
      learnignStandards:null,
      viewstandarselection:false,
      resources: [],
      showSnackbar: false,
      snackbarMessege: "",
      rules: {
        required: (value) => !!value || "Required.",
        max: (v) => v <= 50 || "Max 50 items",
        wholeNum: (v) => {
          const intRegex = /^\d+$/;
          return intRegex.test(v) || "Please enter a valid whole number";
        },
      },
    };
  },
  computed: {
    premium() {
      return this.$store.state.subscription
        ? this.$store.state.subscription.status == "active" ||
            this.$store.state.subscription.status == "active school" ||
            this.$store.state.subscription.status == "trialing"
        : false;
    },
  },

  methods: {
    async sendRequest() {
      this.isLoading = true;

      this.performanceIndicator = this.performanceIndicator
        ? this.performanceIndicator
        : "no specific performace indicator or learning objective";
      this.grade = this.grade
        ? "Yes, this quiz should be suitable for grade level: " + this.grade
        : "no specific grade level";
      this.quizType = this.quizType
        ? "Yes, please use " + this.quizType
        : "no specific preference, it could be mixed";
      this.quizItemCount = this.quizItemCount
        ? this.quizItemCount
        : "any number of questions";
      this.modelItem = this.modelItem
        ? "Please use this item as a sample " + this.modelItem
        : "No, I dont have a sample, you may generate based on your general knowledge about quiz items.";

      let uid = firebaseAuth.currentUser.uid;
      let newresource = {
        what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(),
        promt: [
          {"role": "system", "content": "You are an excellent educator and teacher."},
          {"role": "user","content":"I need help to create a quiz to asses my students, can you help me please"},
          {"role": "assistant","content": "Yes, a quiz is a great way to quicly assess student leatning. On what topic you need me to create this quiz"},
          {"role": "user", "content": this.topic },
          {"role": "assistant","content": "Thank you for letting me know the topic. Do you have any specific learning objectives or performance indicators"},
          {"role": "user", "content": this.performanceIndicator },
          {"role": "assistant","content": "Thank you. Do you need me to create question for a specific grade level?"},
          {"role": "user", "content": this.grade },
          {"role": "assistant","content":"Thank you. Do you prefer a specific item type like multiple choice, true/false, short answer, etc?"},
          {"role": "user", "content": this.quizType },
          {"role": "assistant","content":"how many questions would you like to include in this quiz"},
          {"role": "user", "content": this.quizItemCount },
          {"role": "assistant","content":"Thank you. If you like, I can generate items similar to a given sample item. If you give me a sample item, I will try to create this quiz items similat to the given sample in formatting, content, level of difficulty and learning objective."},
          {"role": "user", "content": this.modelItem },
          {"role": "assistant","content":"Thank you, I have all the details that I need to create a quiz for your studetns. Do you have any other details before I generate the quiz?"},
          {"role": "user","content": "No, that is all, please generate this quiz for me"}
        ],
        uid: uid,
      };
      try {
        const userRef = db.collection("customers").doc(uid);
        const subcollectionRef = userRef.collection("saved");

        const docRef = await subcollectionRef.add(newresource);
        //console.log(docRef.id);
        newresource.id = docRef.id;
      } catch (error) {
        console.error("Error adding document:", error);
      }

      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenai");
        functionRef(newresource);

        this.isLoading = false;

        this.$router.push("/mydocs");
      } catch (error) {
        this.isLoading = false;
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },

    clearSelections() {
      this.topic = null;
      this.grade = null;
      this.quizType = null;
      this.quizItemCount = null;
    },
    
    async searchStandards(){
      this.isLoading=true
       try {
await db.collection('standards').where('name', '==', this.standardState).get().then((res)=>{
res.forEach((doc)=> {
    
    // Add the document data to the array
    this.learnignStandards=doc.data().standards.filter(s => 
    s.Course == this.standardcourse && s.Grade == this.standardGrade );
   
  });
})
  
   } catch (error) {
    console.error('Error adding document:', error);
  }
  this.isLoading = false    
  this.viewstandarselection=true

    },

    validateNumber() {
      const enteredNumber = parseInt(this.quizItemCount); // Parse the input as an integer

      if (
        Number.isInteger(enteredNumber) && // Check if it's an integer
        enteredNumber > 0 && // Check if it's positive
        enteredNumber <= 50 // Check if it's within the range
      ) {
        // Input is a valid whole number within the specified range
        // Do something if needed
      } else {
        this.quizItemCount = ""; // Clear the input if it's not a valid whole number within the range
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .my-card {
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    width: 800px;
  }
}
</style>




